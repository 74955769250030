import { combineReducers } from "redux";
import latestReducer from "./LatestNews";
import { filterReducer } from "./FilterReducers";
import { mcqQuestionsReducer } from "./McqQuestionsReducers";
import postsSlice from "./Posts/postsSlice";
import trendingSlice from "./TrendingNewsReducers/trendingSlice";
import userDetailsSlice from "./UserDetails/userDetailsSlice";
import quizQuesSlice from "./QuizQuesReducers/quizQuesSlice";
import quizListSlice from "./QuizDataReducers/quizListSlice";
import approvalCommitteeSlice from "./ApprovalCommittee/approvalCommitteeSlice";
import forumSlice from "./Forum/forumSlice";


const rootReducer = combineReducers({
    trending: trendingSlice,
    latest: latestReducer,
    quizlist: quizListSlice,
    quizQuesList: quizQuesSlice,
    userDatailsData: userDetailsSlice,
    filterCountList: filterReducer,
    mcqquestions: mcqQuestionsReducer,
    postsData: postsSlice,
    approvalCommittee: approvalCommitteeSlice,
    forumsData: forumSlice
});

export default rootReducer;
