import { createSelector, createSlice } from "@reduxjs/toolkit";
import { forumThunks } from "./forumThunkAction";

const initialState = {
    forumsData: {},
    status: 'idle',
    error: null
}

/* Forum Slice configuration */
const forumsSlice = createSlice({
    name: 'postsData',
    initialState,
    reducers: {
        resetForumsAuthData: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(forumThunks.fetchGlobalForumList.pending, (state, action) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(forumThunks.fetchGlobalForumList.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const newForumsData = action.payload || {};
                state.forumsData = newForumsData;
            })
            .addCase(forumThunks.fetchGlobalForumList.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.payload;
            })
            .addCase(forumThunks.fetchMoreGlobalForumList.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(forumThunks.fetchMoreGlobalForumList.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const newForums = action.payload?.body;
                const newForumsData = action.payload?.body?.content || [];
                state.forumsData.body = { ...newForums, content: [...state.forumsData?.body?.content, ...newForumsData] };
            })
            .addCase(forumThunks.fetchMoreGlobalForumList.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
    }
})

export const getGlobalForumListSelector = createSelector((state) => state?.forumsData, (forumsData) => forumsData);
export const { resetForumsAuthData } = forumsSlice.actions;
export default forumsSlice.reducer;